import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { State } from '../../store/reducer';
import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';
import { startAdvertiseCheckout } from '../../modules/checkout/actions';
import { getJob } from '../../modules/entities/selectors';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { isSignedUp } from '../../modules/recruiters/utils';
import { isReactivatable } from '../../modules/jobs/utils';
import Button from '../../components/Button';

type ConnectorProps = {
    jobId: number;
    className?: string;
};

type ConnectedStateProps = {
    job: Job | null;
    recruiter: Recruiter | null;
};

type ConnectedDispatchProps = {
    startAdvertiseCheckout: typeof startAdvertiseCheckout;
};

type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

export class JobActionReactivate extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { job } = this.props;

        if (!job) {
            return;
        }

        this.props.startAdvertiseCheckout(job);
    }

    render() {
        const { job, recruiter, className } = this.props;

        if (!job || !recruiter || !isSignedUp(recruiter) || !isReactivatable(job)) {
            return null;
        }

        return (
            <Button
                type="button"
                onClick={this.handleClick}
                typeStyle="outlined"
                variationStyle="brand"
                className={className}
            >
                <FormattedMessage id="JOB_ACTION_REACTIVATE.LABEL" />
            </Button>
        );
    }
}

const mapStateToProps = (state: State, props: ConnectorProps): ConnectedStateProps => ({
    job: getJob(state, props.jobId),
    recruiter: getRecruiter(state)
});

const mapDispatchToProps: ConnectedDispatchProps = {
    startAdvertiseCheckout
};

export default connect(mapStateToProps, mapDispatchToProps)(JobActionReactivate);
