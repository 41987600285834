import { Addon, AddonContent } from '../../types';

import records from '../../../../data/addons.yaml';

export function parseAddons(): Addon[] {
    return records.map(
        (record): Addon => {
            return parseAddon(record);
        }
    );
}

function parseAddon(record): Addon {
    return {
        content: (locale: string): AddonContent => {
            const recordContent = record.content[locale];
            return parseAddonContent(recordContent);
        }
    };
}

function parseAddonContent(recordContent): AddonContent {
    return {
        title: recordContent.title,
        description: recordContent.description
    };
}
