import { isSignedUp } from '../../modules/recruiters/utils';
import { isDeletable, isDraft } from '../../modules/jobs/utils';

import config from '../../config';

import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';

export function shouldShow(recruiter: Recruiter, job: Job) {
    if (!config.showDeleteJob) {
        return false;
    }

    return isSignedUp(recruiter) && isDeletable(job) && !isDraft(job);
}
