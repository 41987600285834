import * as React from 'react';

import classes from './ProductJobAdAddOnBox.module.scss';

export type ProductJobAdAddOnBoxProps = {
    title: string;
    description: string;
};

export function ProductJobAdAddOnBox({ title, description }: ProductJobAdAddOnBoxProps) {
    return (
        <section className={classes.root}>
            <h1 className={classes.title}>{title}</h1>

            <p className={classes.description}>{description}</p>
        </section>
    );
}
