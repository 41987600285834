import { createAction } from 'redux-actions';
import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

import { Job } from './types';
import { ListName } from './reducer';

// Factory to create all actions of the job module
const createType = createActionTypeCreator('JOB');

export const CREATE_JOB = createType('CREATE_JOB');
export const createJob = createAction(CREATE_JOB, (data, localCreateId = null) => ({
    data,
    localCreateId
}));

export const REMOVE_CREATE_JOB_LOCAL_ID = createType('REMOVE_CREATE_JOB_LOCAL_ID');
export const removeCreateJobLocalId = createAction(REMOVE_CREATE_JOB_LOCAL_ID, (localCreateId) => ({
    localCreateId
}));

export const JOB_CREATE = createRequestActionTypes(createType('JOB_CREATE'));
export const jobCreate = {
    request: createAction(JOB_CREATE.REQUEST, (localCreateId) => ({ localCreateId })),
    success: createAction(JOB_CREATE.SUCCESS, (localCreateId, response) => ({ localCreateId, data: response.data })),
    failure: createAction(JOB_CREATE.FAILURE, (localCreateId, errors = []) => ({ localCreateId, errors }))
};

export const LOAD_JOBS = createType('LOAD_JOBS');
export const loadJobs = createAction(LOAD_JOBS, (listName: ListName, params: any) => ({
    listName,
    params
}));

export const FETCH_JOBS = createRequestActionTypes(createType('FETCH_JOBS'));
export const fetchJobs = {
    request: createAction(FETCH_JOBS.REQUEST, (listName: ListName, page: number) => ({
        listName,
        page
    })),

    success: createAction(FETCH_JOBS.SUCCESS, (listName: ListName, page: any, response: any) => ({
        listName,
        page,
        data: response.data
    })),

    failure: createAction(FETCH_JOBS.FAILURE, (listName: ListName, errors: any) => ({ listName, errors }))
};

export const LOAD_JOB = createType('LOAD_JOB');
export const loadJob = createAction(LOAD_JOB, (jobId: number) => ({ jobId }));

export const FETCH_JOB = createRequestActionTypes(createType('FETCH_JOB'));
export const fetchJob = {
    request: createAction(FETCH_JOB.REQUEST, (jobId: number) => ({ jobId })),
    success: createAction(FETCH_JOB.SUCCESS, (jobId: number, response: any) => ({ jobId, data: response.data })),
    failure: createAction(FETCH_JOB.FAILURE, (jobId: number, errors: any) => ({ jobId, errors }))
};

export const UPDATE_JOB = createType('UPDATE_JOB');
export const updateJob = createAction(UPDATE_JOB, (jobId, newJob) => ({
    jobId,
    newJob
}));

export const JOB_UPDATE = createRequestActionTypes(createType('JOB_UPDATE'));
export const jobUpdate = {
    request: createAction(JOB_UPDATE.REQUEST, (jobId) => ({ jobId })),
    success: createAction(JOB_UPDATE.SUCCESS, (jobId, response) => ({ jobId, data: response.data })),
    failure: createAction(JOB_UPDATE.FAILURE, (jobId, errors) => ({ jobId, errors }))
};

export const DELETE_JOB = createType('DELETE_JOB');
export const deleteJob = createAction(DELETE_JOB, (jobId) => ({
    jobId
}));

export const JOB_DELETE = createRequestActionTypes(createType('JOB_DELETE'));
export const jobDelete = {
    request: createAction(JOB_DELETE.REQUEST, (jobId) => ({ jobId })),
    success: createAction(JOB_DELETE.SUCCESS, (jobId) => ({ jobId })),
    failure: createAction(JOB_DELETE.FAILURE, (jobId, errors) => ({ jobId, errors }))
};

export const ARCHIVE_JOB = createType('ARCHIVE_JOB');
export const archiveJob = createAction(ARCHIVE_JOB, (jobId) => ({
    jobId
}));

export const JOB_ARCHIVE = createRequestActionTypes(createType('ARCHIVE_JOB'));
export const jobArchive = {
    request: createAction(JOB_ARCHIVE.REQUEST, (jobId) => ({ jobId })),
    success: createAction(JOB_ARCHIVE.SUCCESS, (jobId, response) => ({ jobId, data: response.data })),
    failure: createAction(JOB_ARCHIVE.FAILURE, (jobId, errors) => ({ jobId, errors }))
};

export const UPGRADE_JOB = createType('UPGRADE_JOB');
export const upgradeJob = createAction(UPGRADE_JOB, (jobId: number) => ({ jobId }));

export const COPY_JOB = createType('COPY_JOB');
export const copyJob = createAction(COPY_JOB, (id: number) => ({ id }));

export const STORE_JOB = createType('STORE_JOB');
export const storeJob = createAction(STORE_JOB, (job: Job) => ({ job }));
