import dateFnsLocaleDe from 'date-fns/locale/de';

import { registerLocale } from 'react-datepicker';

export { LOCALE_DE, LOCALE_EN, appLocales, fallbackLocale, resolveLocale } from './locale';
export type { Locale } from './locale';

export { allMessages } from './messages';
export type { Messages } from './messages';

export { enhanceIntl } from './intlEnhancer';

registerLocale('de', dateFnsLocaleDe);
