import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { handleError } from '../../services/errorHandler';

import CheckoutHeader from '../../components/CheckoutHeader/CheckoutHeader';
import PackageChooser from '../../components/PackageChooser';
import {
    getProductByAdType,
    getProductById,
    getProductByProductSlug,
    getProducts
} from '../../modules/checkout/repositories/products';
import {
    CheckoutProcessState,
    CheckoutProcessState$Advertise$Package,
    CheckoutProcessState$Advertise$Product,
    ProductV2Id
} from '../../modules/checkout/types';
import {
    canSelectPackage,
    canSelectProduct,
    getAdvertisementFromCheckoutProcessState,
    isCheckoutProcessStateAdvertisePackage,
    isAllowedToOrder as isRecruiterAllowedToOrder
} from '../../modules/checkout/utils';
import { Job } from '../../modules/jobs/types';
import { fetchPackages } from '../../modules/packages/actions';
import { getPackages, getFetchStatus as getPackagesFetchStatus } from '../../modules/packages/selectors';
import { Package } from '../../modules/packages/types';
import { Recruiter } from '../../modules/recruiters/types';
import { FetchStatus } from '../../modules/types';
import { State as ApplicationState } from '../../store/reducer';
import { continueToNextStep } from './actions';

import { getPricesRoute, getSignupRoute } from '../../routes';

import CheckoutProductPageActions from './CheckoutProductPageActions';
import CheckoutProductPageExplanation from './CheckoutProductPageExplanation';
import CheckoutProductPageProductCategoryJobAds from './CheckoutProductPageProductCategoryJobAds';
import CheckoutProductPageProductCategoryJobAdsAddons from './CheckoutProductPageProductCategoryJobAdsAddons';
import CheckoutProductPageProductCategorySpotlightPortfolio from './CheckoutProductPageProductCategorySpotlightPortfolio';
import CheckoutProductPageTabs, { Tab as CheckoutProductPageTab } from './CheckoutProductPageTabs';

import classes from './CheckoutProductPage.module.scss';

const PUBLICATION_METHOD_BY_JOB_AD = 'by-job-ad';
const PUBLICATION_METHOD_BY_PACKAGE = 'by-package';
type PublicationMethod = typeof PUBLICATION_METHOD_BY_JOB_AD | typeof PUBLICATION_METHOD_BY_PACKAGE;

export type CheckoutProductPageProps = RouteComponentProps & {
    recruiter: Recruiter | null;

    checkoutProcessState: CheckoutProcessState;

    job: Job | null;
    jobIsLoading: boolean;
};

type CheckoutProductPageConnectedStateProps = {
    packagesFetchStatus: FetchStatus;
    packages: Package[];
};

type CheckoutProductPageConnectedDispatchProps = {
    fetchPackages: typeof fetchPackages;
    continueToNextStep: typeof continueToNextStep;
};

type FullCheckoutProductPageProps = CheckoutProductPageProps &
    CheckoutProductPageConnectedStateProps &
    CheckoutProductPageConnectedDispatchProps;

type State = {
    initialized: boolean;

    currentPublicationMethod: PublicationMethod;

    selectedProductId: ProductV2Id | null;
    selectedPackageId: number | null;
};

class CheckoutProductPage extends React.Component<FullCheckoutProductPageProps, State> {
    constructor(props: FullCheckoutProductPageProps) {
        super(props);

        this.state = {
            initialized: false,

            currentPublicationMethod: PUBLICATION_METHOD_BY_JOB_AD,

            selectedProductId: null,
            selectedPackageId: null
        };

        this.handleChangePublicationMethod = this.handleChangePublicationMethod.bind(this);
        this.handleSelectProductId = this.handleSelectProductId.bind(this);
        this.handleChangeSelectedPackageId = this.handleChangeSelectedPackageId.bind(this);
        this.handleClickContinue = this.handleClickContinue.bind(this);
    }

    componentDidMount() {
        const {
            checkoutProcessState,

            fetchPackages
        } = this.props;

        // The checkout process state is the core and we don't want to run any actions if the state is not available.
        if (!checkoutProcessState) {
            return;
        }

        if (!!getAdvertisementFromCheckoutProcessState(checkoutProcessState)) {
            fetchPackages({
                hasAvailableAdvertisements: true
            });
        }
    }

    componentDidUpdate() {
        this.initializeState(this.props);
    }

    initializeState(props: FullCheckoutProductPageProps) {
        const { initialized } = this.state;

        if (!!initialized) {
            return;
        }

        const checkoutProcessState = props.checkoutProcessState;
        const { jobIsLoading, job, packagesFetchStatus, packages } = props;

        if (!checkoutProcessState || !checkoutProcessState.data) {
            return;
        }

        // TOOD: When does this happen?
        if (isCheckoutProcessStateAdvertisePackage(checkoutProcessState)) {
            const checkoutProcessStateData: CheckoutProcessState$Advertise$Package['data'] = checkoutProcessState.data as any;

            this.setState(() => ({
                initialized: true
            }));

            this.setSelectedPackageId(checkoutProcessStateData.packageToUse);
            this.setState(() => ({
                currentPublicationMethod: PUBLICATION_METHOD_BY_PACKAGE
            }));

            return;
        }

        // TODO: When does this happen?
        if (!!checkoutProcessState.data.product) {
            const checkoutProcessStateData: CheckoutProcessState$Advertise$Product['data'] = checkoutProcessState.data as any;

            const product = getProductByProductSlug(checkoutProcessStateData.product);

            this.setState(() => ({
                initialized: true
            }));

            this.setSelectedProduct(product.id);
            // this.setSelectedQuantity(checkoutProcessStateData.quantity || 1);
            this.setState(() => ({
                currentPublicationMethod: PUBLICATION_METHOD_BY_JOB_AD
            }));

            return;
        }

        const jobWasLoaded = !jobIsLoading && !!job;

        if (!jobWasLoaded || packagesFetchStatus !== 'loaded') {
            return;
        }

        // TODO: When does this happen?
        if (canSelectPackage(checkoutProcessState) && !canSelectProduct(checkoutProcessState) && !packages.length) {
            this.prefillForOrder(checkoutProcessState, job);
        }

        this.setState(() => ({
            initialized: true
        }));

        const firstPackage = packages[0];

        if (!firstPackage) {
            return;
        }

        this.setSelectedPackageId(firstPackage.id);
        this.setState(() => ({
            currentPublicationMethod: PUBLICATION_METHOD_BY_PACKAGE
        }));
    }

    setPublicationMethod(publicationMethod: PublicationMethod) {
        const { checkoutProcessState, job, packages } = this.props;
        const { currentPublicationMethod: prevPublicationMethod } = this.state;

        if (prevPublicationMethod === publicationMethod) {
            return;
        }

        this.setState(() => ({
            currentPublicationMethod: publicationMethod
        }));

        if (publicationMethod === PUBLICATION_METHOD_BY_JOB_AD) {
            this.prefillForOrder(checkoutProcessState, job);
        } else if (publicationMethod === PUBLICATION_METHOD_BY_PACKAGE) {
            this.prefillForSelectedPackage(checkoutProcessState, packages);
        }
    }

    setSelectedProduct(nextProductId: string | null) {
        // const { selectedProduct: prevProductId } = this.state;

        // If no truthy value was passed we clear the current selection
        if (!nextProductId) {
            this.setState(() => ({
                selectedProductId: null
            }));

            return;
        }

        let product;
        try {
            product = getProductById(nextProductId);
        } catch (error) {
            handleError(error);

            // TODO: We should show a message to the user which tells them that something went wrong.

            return;
        }

        // If the users selected a product which is only availabe on request we reset the stage
        if (product.onRequest) {
            this.resetSelectedProduct();

            return;
        }

        this.setState(() => ({
            selectedProductId: product.id
        }));
    }

    resetSelectedProduct() {
        this.setSelectedProduct(null);
    }

    setSelectedPackageId(packageId: number | null) {
        this.setState(() => ({
            selectedPackageId: packageId
        }));
    }

    resetSelectedPackage() {
        this.setSelectedPackageId(null);
    }

    prefillForOrder(checkoutProcessState: CheckoutProcessState | null, job: Job | null) {
        // Clear other option
        this.resetSelectedPackage();

        if (!checkoutProcessState || !job) {
            return;
        }

        // We don't want to prefill if the user can select a product by themself
        if (canSelectProduct(checkoutProcessState)) {
            return;
        }

        let product: any | null = null;
        try {
            product = !!job.ad_type ? getProductByAdType(job.ad_type) : null;
        } catch (error) {
            // Nothing
        }

        const productId = !!product ? product.id : null;

        this.setSelectedProduct(productId);
    }

    prefillForSelectedPackage(checkoutProcessState: CheckoutProcessState | null, packages: Package[]) {
        // Clear other option
        this.resetSelectedProduct();

        if (!checkoutProcessState || !getAdvertisementFromCheckoutProcessState(checkoutProcessState)) {
            return;
        }

        const firstPackage = packages[0];
        if (!firstPackage) {
            return;
        }

        this.setSelectedPackageId(firstPackage.id);
    }

    handleChangePublicationMethod(nextTab: PublicationMethod) {
        this.setPublicationMethod(nextTab);
    }

    handleSelectProductId(productId: ProductV2Id) {
        this.setPublicationMethod(PUBLICATION_METHOD_BY_JOB_AD);
        this.setSelectedProduct(productId);

        this.continue({
            ...this.state,
            currentPublicationMethod: PUBLICATION_METHOD_BY_JOB_AD,
            selectedProductId: productId
        });
    }

    handleChangeSelectedPackageId(packageId: number) {
        this.setSelectedPackageId(packageId);
    }

    handleClickContinue(event: Event) {
        event.preventDefault();

        this.continue(this.state);
    }

    continue({
        currentPublicationMethod,

        selectedProductId,
        selectedPackageId
    }: State) {
        const {
            history,

            recruiter,

            checkoutProcessState,

            continueToNextStep
        } = this.props;

        if (!checkoutProcessState) {
            return;
        }

        if (!isRecruiterAllowedToOrder(recruiter)) {
            history.push(getSignupRoute({ destinationUrl: getPricesRoute() }));
            return;
        }

        const checkoutProcessStateData: {
            product: string | null;
            packageToUse: number | null;
        } = { ...checkoutProcessState.data } as any;

        if (currentPublicationMethod === PUBLICATION_METHOD_BY_PACKAGE) {
            if (!selectedPackageId) {
                throw new Error('No package was selected');
            }

            checkoutProcessStateData.product = null;
            checkoutProcessStateData.packageToUse = selectedPackageId;
        } else if (currentPublicationMethod === PUBLICATION_METHOD_BY_JOB_AD) {
            if (!selectedProductId) {
                throw new Error('No product was selected');
            }

            const product = getProductById(selectedProductId);

            checkoutProcessStateData.product = product.product_slug;
            checkoutProcessStateData.packageToUse = null;
        }

        const nextCheckoutProcessState = {
            ...checkoutProcessState,
            data: checkoutProcessStateData
        };

        continueToNextStep(nextCheckoutProcessState);
    }

    render() {
        const { recruiter, checkoutProcessState, job, jobIsLoading, packagesFetchStatus } = this.props;
        const {
            currentPublicationMethod,

            selectedProductId,
            selectedPackageId
        } = this.state;

        if (!checkoutProcessState || !recruiter) {
            return null;
        }

        const needsAdditionalData = !!getAdvertisementFromCheckoutProcessState(checkoutProcessState);
        const jobLoaded = !(needsAdditionalData && !!jobIsLoading && !!job);
        const packagesLoaded = !(needsAdditionalData && packagesFetchStatus !== 'loaded');

        if (!jobLoaded || !packagesLoaded) {
            return null;
        }

        const shouldShowActions = currentPublicationMethod === PUBLICATION_METHOD_BY_PACKAGE;

        return (
            <React.Fragment>
                <CheckoutHeader
                    currentStep="product"
                    recruiter={recruiter}
                    checkoutProcessState={checkoutProcessState}
                    job={job}
                    headline={true}
                    progressBar={false}
                />
                {checkoutProcessState && <CheckoutProductPageExplanation checkoutProcessState={checkoutProcessState} />}

                <div
                    className={classNames(classes.root, {
                        [classes.notAllowedToOrder]: !isRecruiterAllowedToOrder(recruiter)
                    })}
                >
                    {this.renderCheckoutOptions()}
                </div>
                {shouldShowActions && (
                    <CheckoutProductPageActions
                        selectedProductId={selectedProductId}
                        selectedPackageId={selectedPackageId}
                        onClickContinue={this.handleClickContinue}
                    />
                )}
            </React.Fragment>
        );
    }

    renderProducts() {
        const { recruiter, checkoutProcessState, packages } = this.props;
        const { selectedProductId: selectedProduct } = this.state;

        if (!recruiter || !checkoutProcessState) {
            return null;
        }

        const bookableProducts = getProducts({
            bookable: true
        });
        const nonBookableProducts = getProducts({
            bookable: false
        });

        const productSelectable = canSelectProduct(checkoutProcessState);
        const packageSelectable =
            canSelectPackage(checkoutProcessState) && isRecruiterAllowedToOrder(recruiter) && !!packages.length;
        const hideOtherProducts = productSelectable && packageSelectable;

        return (
            <React.Fragment>
                <div className="container">
                    <CheckoutProductPageProductCategoryJobAds
                        bookableProducts={bookableProducts}
                        nonBookableProducts={!hideOtherProducts ? nonBookableProducts : []}
                        selectedProductId={selectedProduct}
                        onSelectProduct={this.handleSelectProductId}
                        disableNotSelectedProducts={!productSelectable}
                        actionType={!productSelectable ? 'continue' : 'select'}
                    />
                </div>

                {!hideOtherProducts && (
                    <React.Fragment>
                        <CheckoutProductPageProductCategoryJobAdsAddons />
                        <CheckoutProductPageProductCategorySpotlightPortfolio />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    renderCheckoutOptions() {
        const { recruiter, checkoutProcessState, packages } = this.props;
        const { selectedPackageId: selectedPackage } = this.state;

        if (!recruiter || !checkoutProcessState) {
            return null;
        }

        const productSelectable = canSelectProduct(checkoutProcessState);
        const packageSelectable =
            canSelectPackage(checkoutProcessState) && isRecruiterAllowedToOrder(recruiter) && !!packages.length;

        const productJobAdsSection = this.renderProducts();
        const packageChooser = packageSelectable && !!selectedPackage && (
            <PackageChooser
                items={packages}
                selectedPackage={selectedPackage}
                onSelect={this.handleChangeSelectedPackageId}
                key="CheckoutProductPage__package-chooser"
            />
        );

        let optionContent: React.ReactNode = productJobAdsSection;
        if (productSelectable && packageSelectable) {
            optionContent = this.renderCheckoutOptionsAsTabs(productJobAdsSection, packageChooser);
        } else if (packageSelectable) {
            optionContent = packageChooser;
        }

        return optionContent;
    }

    renderCheckoutOptionsAsTabs(productJobAdsSection: React.ReactNode, packageChooser: React.ReactNode) {
        const { currentPublicationMethod } = this.state;

        return (
            <React.Fragment>
                <CheckoutProductPageTabs
                    activeTab={currentPublicationMethod}
                    onClickTab={this.handleChangePublicationMethod}
                >
                    <CheckoutProductPageTab id={PUBLICATION_METHOD_BY_JOB_AD}>
                        <FormattedMessage id="CHECKOUT_PRODUCT_PAGE.OPTIONS.PACKAGE_CALCULATOR.TAB_LABEL" />
                    </CheckoutProductPageTab>
                    <CheckoutProductPageTab id={PUBLICATION_METHOD_BY_PACKAGE}>
                        <FormattedMessage id="CHECKOUT_PRODUCT_PAGE.OPTIONS.PACKAGE_CHOOSER.TAB_LABEL" />
                    </CheckoutProductPageTab>
                </CheckoutProductPageTabs>
                {currentPublicationMethod === PUBLICATION_METHOD_BY_JOB_AD && productJobAdsSection}
                {currentPublicationMethod === PUBLICATION_METHOD_BY_PACKAGE && packageChooser}
            </React.Fragment>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    props: CheckoutProductPageProps
): CheckoutProductPageConnectedStateProps {
    const checkoutProcessState = props.checkoutProcessState;
    const job = props.job;

    let packagesFetchStatus: FetchStatus = 'none';
    let packages: Package[] = [];

    // The checkout process state is our core value which we need to determine additional
    if (!checkoutProcessState) {
        return {
            packagesFetchStatus,
            packages
        };
    }

    if (checkoutProcessState.method === 'advertise') {
        const advertisement = checkoutProcessState.data.advertisement;

        packagesFetchStatus = getPackagesFetchStatus(state);
        packages = getPackages(state);

        if (!!job && packagesFetchStatus === 'loaded' && advertisement.action === 'prolong') {
            packages = packages.filter((pkg) => job.ad_type === pkg.ad_type);
        }
    }

    return {
        packagesFetchStatus,
        packages
    };
}

const mapDispatchToProps: CheckoutProductPageConnectedDispatchProps = {
    fetchPackages,
    continueToNextStep
};

export default withRouter(
    connect<
        CheckoutProductPageConnectedStateProps,
        CheckoutProductPageConnectedDispatchProps,
        CheckoutProductPageProps,
        ApplicationState
    >(
        mapStateToProps,
        mapDispatchToProps
    )(CheckoutProductPage)
);
