import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getAddons } from '../../../modules/checkout/repositories/addons';
import { Addon } from '../../../modules/checkout/types';

import ContactButton from '../../../components/CheckoutContactButton';
import ProductJobAdAddOnList, { ProductJobAdAddOnBox } from '../../../components/ProductJobAdAddOnList';

import CheckoutProductPageHeadline from '../CheckoutProductPageHeadline';

import { links } from '../../../constants';

import classes from './CheckoutProductPageProductCategoryJobAdsAddons.module.scss';

function CheckoutProductPageProductCategoryJobAdsAddons() {
    const { locale } = useIntl();

    const addons = getAddons().map((addon: Addon, index: number) => {
        const content = addon.content(locale);

        return <ProductJobAdAddOnBox key={index} title={content.title} description={content.description} />;
    });

    return (
        <div className="container">
            <CheckoutProductPageHeadline
                subtitle={<FormattedMessage id="CHECKOUT_PRODUCT_PAGE.JOB_AD_ADDONS.SUBTITLE" />}
            >
                <FormattedMessage id="CHECKOUT_PRODUCT_PAGE.JOB_AD_ADDONS.TITLE" />
            </CheckoutProductPageHeadline>

            <ProductJobAdAddOnList className={classes.list}>
                {addons}

                <div className={classes.buttonContainer}>
                    <ContactButton
                        component="a"
                        href={links.calendly[locale]}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.button}
                    />
                </div>
            </ProductJobAdAddOnList>
        </div>
    );
}

export default CheckoutProductPageProductCategoryJobAdsAddons;
