import * as React from 'react';
import classNames from 'classnames';

import classes from './ProductJobAdAddOnList.module.scss';

export type ProductJobAdAddOnListProps = {
    children: React.ReactNode;
    className?: string;
};

function ProductJobAdAddOnList({ children, className }: ProductJobAdAddOnListProps) {
    return (
        <ul className={classNames(classes.root, className)}>
            {React.Children.map(children, (child, index) => (
                <li key={`child-${index}`} className={classes.item}>
                    {child}
                </li>
            ))}
        </ul>
    );
}

export default ProductJobAdAddOnList;
