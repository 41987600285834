import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Product from '../Product';
import { ProductV2Id } from '../../modules/checkout/types';

import { ProductProps } from '../Product';
import ProductActionButton from '../Product/ProductActionButton';

export type ProductInteractiveProps = Omit<ProductProps, 'action'> & {
    id: string;
    onSelectProduct: (productId: ProductV2Id) => void;
    actionType?: 'select' | 'continue';
};

function ProductInteractive({
    disabled,

    id,
    onSelectProduct,
    actionType = 'select',
    ...props
}: ProductInteractiveProps) {
    const handleClickAction = () => {
        onSelectProduct(id);
    };

    return (
        <Product
            {...props}
            disabled={disabled}
            action={
                <ProductActionButton onClick={handleClickAction} disabled={disabled}>
                    {actionType === 'continue' ? (
                        <FormattedMessage id="PRODUCT_FOOTER_INPUT.BUTTON_CONTINUE" />
                    ) : (
                        <FormattedMessage id="PRODUCT_FOOTER_INPUT.BUTTON_SELECT" />
                    )}
                </ProductActionButton>
            }
        />
    );
}

export default ProductInteractive;
