import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog, { DialogProps } from '../DialogV2';
import DialogTitle from '../DialogV2/DialogTitle';
import DialogContent from '../DialogV2/DialogContent';
import DialogActions from '../DialogV2/DialogActions';
import Button from '../Button';

import './style.scss';

type Props = DialogProps & {
    onSubmit: (arg1: boolean) => void;
};

type State = {
    doNotShowAgain: boolean;
};

class JobDialogUpgrade extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            doNotShowAgain: false
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            doNotShowAgain: event.target.checked
        });
    }

    handleClose() {
        const { onClose } = this.props;

        this.setState({
            doNotShowAgain: false
        });

        if (typeof onClose === 'function') {
            onClose();
        }
    }

    handleSubmit() {
        const { onSubmit } = this.props;
        const { doNotShowAgain } = this.state;

        onSubmit(doNotShowAgain);
    }

    renderDoNotShowAgain() {
        const { doNotShowAgain } = this.state;

        return (
            <div className="JobDialogUpgrade__not-show-again">
                <span className="tf-checkbox">
                    <input
                        type="checkbox"
                        id="job-upgrade-dialog-checkbox"
                        checked={doNotShowAgain}
                        onChange={this.handleCheckboxChange}
                    />

                    <label className="tf-checkbox__label" htmlFor="job-upgrade-dialog-checkbox">
                        <span className="tf-checkbox__faux"></span>
                        <span className="tf-checkbox__label-text">
                            <FormattedMessage id="CONSTANT.DIALOG.DO_NOT_SHOW_AGAIN" />
                        </span>
                    </label>
                </span>
            </div>
        );
    }

    render() {
        const { open } = this.props;

        return (
            <Dialog open={open} onClose={this.handleClose}>
                <DialogTitle>
                    <FormattedMessage id="JOB_UPGRADE_DIALOG.TITLE" />
                </DialogTitle>
                <DialogContent>
                    <FormattedMessage id="JOB_UPGRADE_DIALOG.DESCRIPTION" />
                </DialogContent>
                <DialogActions className="JobDialogUpgrade__actions">
                    {this.renderDoNotShowAgain()}
                    <Button typeStyle="flat" variationStyle="normal" component="button" onClick={this.handleClose}>
                        <FormattedMessage id="CONSTANT.DIALOG.CANCEL" />
                    </Button>
                    <Button typeStyle="flat" variationStyle="normal" component="button" onClick={this.handleSubmit}>
                        <FormattedMessage id="JOB_UPGRADE_DIALOG.ACTION.SUBMIT" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default JobDialogUpgrade;
