import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getAdTypeMessageId } from './AdType.utils';

export type AdTypeProps = {
    adType: string | null;
};

function AdType({ adType }: AdTypeProps) {
    if (!adType) {
        return null;
    }

    return <FormattedMessage id={getAdTypeMessageId(adType)} />;
}

export default AdType;
