import * as React from 'react';

import { Job } from '../../modules/jobs/types';

import JobActionUpgrade from '../JobActionUpgrade';
import JobActionAdvertise from '../JobActionAdvertise';
import JobActionProlong from '../JobActionProlong';
import JobActionReactivate from '../JobActionReactivate';
import JobActionEdit from '../../components/JobActionEdit';
import JobActionDelete from '../JobActionDelete';
import JobActionDiscard from '../JobActionDiscard';
import JobActionArchive from '../JobActionArchive';
import JobActionRemoveFromApp from '../JobActionRemoveFromApp';
import JobActionCandidates from '../../components/JobActionCandidates';
import JobActionViewInApp from '../JobActionViewInApp';
import JobActionDuplicate from '../JobActionDuplicate';

import classes from './JobDetailsActions.module.scss';

export type JobDetailsActionsProps = {
    job: Job;
};

function JobDetailsActions({ job }: JobDetailsActionsProps) {
    return (
        <div className={classes.root}>
            <JobActionUpgrade jobId={job.id} />
            <JobActionAdvertise jobId={job.id} />
            <JobActionProlong jobId={job.id} />
            <JobActionReactivate jobId={job.id} />
            <JobActionEdit job={job} />
            <JobActionDuplicate jobId={job.id} />
            <JobActionDelete jobId={job.id} />
            <JobActionDiscard jobId={job.id} />
            <JobActionArchive jobId={job.id} />
            <JobActionRemoveFromApp jobId={job.id} />
            <JobActionCandidates job={job} />
            <JobActionViewInApp job={job} />
        </div>
    );
}

export default JobDetailsActions;
