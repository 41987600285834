import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { State as ApplicationState } from '../../store/reducer';

import { getPackageFetchStatus, getPackage } from '../../modules/entities/selectors';
import { fetchPackage } from '../../modules/packages/actions';

import { CheckoutProcessState } from '../../modules/checkout/types';
import { isCheckoutProcessStateAdvertisePackage } from '../../modules/checkout/utils/helpers';
import { Package } from '../../modules/packages/types';
import { FetchStatus } from '../../modules/types';

type ConnectorProps = {
    checkoutProcessState: CheckoutProcessState;

    onSubmit: Function;
    actions: React.ReactNode;
};

type MappedProps = {
    packageId: number | null;
    packageFetchStatus: FetchStatus;
    package: Package | null;
};

type Props = ConnectorProps &
    MappedProps & {
        fetchPackage: typeof fetchPackage;
    };

class CheckoutConfirmPackageUsageForm extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { packageId: pkgId } = this.props;

        if (!!pkgId) {
            this.props.fetchPackage(pkgId);
        }
    }

    handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        this.props.onSubmit();
    }

    render() {
        const {
            packageFetchStatus,

            actions
        } = this.props;

        if (packageFetchStatus !== 'loaded') {
            return null;
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <h2 className="h3 text-center">
                    <FormattedMessage
                        id="CHECKOUT_CONFIRM_PACKAGE_USAGE_FORM.TITLE"
                        values={{
                            packageTermSingular: <FormattedMessage id={'CONSTANT.PACKAGE_TERM.SINGULAR'} />
                        }}
                    />
                </h2>

                {this.renderContent()}

                {actions}
            </form>
        );
    }

    renderContent() {
        const { packageFetchStatus, package: pkg } = this.props;

        if (packageFetchStatus !== 'loaded' || !pkg) {
            return null;
        }

        return (
            <div className="form-horizontal">
                <div className="form-group">
                    <label className="control-label col-sm-4">
                        <FormattedMessage id="CHECKOUT_CONFIRM_PACKAGE_USAGE_FORM.PACKAGE_TYPE.LABEL" />
                    </label>

                    <div className="col-sm-6">
                        <p className="form-control-static">
                            <FormattedMessage
                                id="CHECKOUT_CONFIRM_PACKAGE_USAGE_FORM.PACKAGE_TYPE.VALUE"
                                values={{
                                    type: <FormattedMessage id={`CONSTANT.PRODUCT.${pkg.ad_type}`} />
                                }}
                            />
                        </p>
                    </div>
                </div>

                <div className="form-group">
                    <label className="control-label col-sm-4">
                        <FormattedMessage id="CHECKOUT_CONFIRM_PACKAGE_USAGE_FORM.REMAINING_CONTINGENT.LABEL" />
                    </label>

                    <div className="col-sm-6">
                        <p className="form-control-static">
                            <FormattedMessage
                                id="CHECKOUT_CONFIRM_PACKAGE_USAGE_FORM.REMAINING_CONTINGENT.VALUE"
                                values={{
                                    remaining: pkg.remaining_contingent,
                                    total: pkg.total_contingent
                                }}
                            />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, props: ConnectorProps): MappedProps {
    const checkoutProcessState = props.checkoutProcessState;

    let pkgId: number | null = null;
    let pkgFetchStatus: FetchStatus = 'none';
    let pkg: Package | null = null;

    if (isCheckoutProcessStateAdvertisePackage(checkoutProcessState)) {
        pkgId = checkoutProcessState.data.packageToUse;

        pkgFetchStatus = getPackageFetchStatus(state, pkgId);
        pkg = getPackage(state, pkgId);
    }

    return {
        packageId: pkgId,
        packageFetchStatus: pkgFetchStatus,
        package: pkg
    };
}

const mapDispatchToProps = {
    fetchPackage: fetchPackage
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutConfirmPackageUsageForm);
