import { ProductV2 } from '../types';

export type ProductV2WithAdType = Omit<ProductV2, 'ad_type'> & {
    ad_type: NonNullable<ProductV2['ad_type']>;
    price: NonNullable<ProductV2['price']>;
};

export function hasAdType(product: ProductV2): product is ProductV2WithAdType {
    return product.ad_type !== undefined;
}
