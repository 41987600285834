import classNames from 'classnames';
import * as React from 'react';

import { ProductContext } from './ProductContext';
import ProductSection from './ProductSection';

import { Section } from './Product.types';

import classes from './Product.module.scss';

export type ProductProps = {
    title: React.ReactNode;
    subtitle?: React.ReactNode;

    /**
     * The formatted product price.
     */
    price: React.ReactNode;

    sections: Section[];

    /**
     * If the product should be promoted you have to pass the promote text.
     */
    promote?: React.ReactNode;

    action?: React.ReactNode | null;
    overlay?: React.ReactNode | null;

    disabled?: boolean;

    className?: string;
    subtitleClassName?: string;

    targetOrientation?: 'vertical' | 'horizontal';

    forceTopSpacing?: boolean;
};

function Product({
    title,
    subtitle,

    price,

    sections,

    promote = false,

    action,
    overlay,

    disabled,

    className,
    subtitleClassName,

    targetOrientation = 'vertical',
    forceTopSpacing = false
}: ProductProps) {
    return (
        <ProductContext.Provider value={{ promote: !!promote, targetOrientation }}>
            <section
                className={classNames(
                    classes.root,
                    {
                        [classes.promote]: promote,
                        [classes.forceTopSpacing]: forceTopSpacing,
                        [classes.horizontal]: targetOrientation === 'horizontal'
                    },
                    className
                )}
                aria-disabled={disabled}
            >
                <div className={classes.content}>
                    {promote && <div className={classes.promoteTag}>{promote}</div>}

                    <div className={classes.header}>
                        <h1 className={classes.headerTitle}>{title}</h1>
                        {!!price && <div className={classes.headerPrice}>{price}</div>}
                        {subtitle && (
                            <div className={classNames(classes.headerSubtitle, subtitleClassName)}>{subtitle}</div>
                        )}
                    </div>

                    {!!action && <div className={classes.action}>{action}</div>}

                    {sections.length > 0 && (
                        <div className={classes.sections}>
                            {sections.map((section: Section, index: number) => (
                                <ProductSection key={index}>
                                    <div dangerouslySetInnerHTML={{ __html: section.content }} />
                                </ProductSection>
                            ))}
                        </div>
                    )}

                    {!!overlay && <div className={classes.overlay}>{overlay}</div>}
                </div>
            </section>
        </ProductContext.Provider>
    );
}

export default Product;
