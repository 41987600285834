import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { State as ApplicationState } from '../../store/reducer';
import { Job } from '../../modules/jobs/types';
import JobDialogArchive from '../../components/JobDialogArchive';
import { archiveJob } from '../../modules/jobs/actions';
import { getJob } from '../../modules/entities/selectors';
import { isPremium, isArchivable } from '../../modules/jobs/utils';
import { addActionNotification } from '../../modules/notifications/actions';
import * as localStorage from '../../services/localStorage';
import Button from '../../components/Button';

type ConnectorProps = {
    jobId: number;
    className?: string;
};

type ConnectedStateProps = {
    job: Job | null;
};

type ConnectedDispatchProps = {
    archiveJob: typeof archiveJob;
    addActionNotification: typeof addActionNotification;
};

type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    showDialog: boolean;
};

export class JobActionRemoveFromApp extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDialog: false
        };

        this.closeDialog = this.closeDialog.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    openDialog() {
        this.setState({ showDialog: true });
    }

    closeDialog() {
        this.setState({ showDialog: false });
    }

    handleClick() {
        const notShowAgain = localStorage.get('NotShowJobRemoveFromAppDialogAgain');

        if (notShowAgain) {
            this.handleSubmit();
            return;
        }

        this.openDialog();
    }

    handleSubmit(doNotShowAgain?: boolean) {
        const { jobId, archiveJob, addActionNotification } = this.props;

        if (!!doNotShowAgain) {
            localStorage.set('NotShowJobRemoveFromAppDialogAgain', true);
        }

        archiveJob(jobId);
        this.closeDialog();
        addActionNotification('JOB_ACTION_REMOVE_FROM_APP.NOTIFICATION');
    }

    render() {
        const { job, className } = this.props;
        const { showDialog } = this.state;

        if (!job || isPremium(job) || !isArchivable(job)) {
            return null;
        }

        return (
            <React.Fragment>
                <Button
                    type="button"
                    onClick={this.handleClick}
                    typeStyle="outlined"
                    variationStyle="normal"
                    className={className}
                >
                    <FormattedMessage id="JOB_ACTION_REMOVE_FROM_APP.LABEL" />
                </Button>
                <JobDialogArchive open={showDialog} job={job} onSubmit={this.handleSubmit} onClose={this.closeDialog} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    job: getJob(state, props.jobId)
});

const mapDispatchToProps: ConnectedDispatchProps = {
    archiveJob,
    addActionNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(JobActionRemoveFromApp);
