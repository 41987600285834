import { setupEnvironment } from '../environment';
import { Configuration } from './types';

export type CommonConfig = Pick<
    Configuration,
    | 'appVersion'
    | 'appEnvironment'
    | 'dashboardUrl'
    | 'weblicantUrl'
    | 'sentryDsn'
    | 'sentryRelease'
    | 'sentryTracesSampleRate'
    | 'analytics'
    | 'googleMapsApiKey'
    | 'leaflet'
    | 'deeplink'
    | 'showDeleteJob'
    | 'showRequestDocuments'
    | 'showCalendlyLink'
    | 'showAdTypeOnlyForPremiumJob'
>;

export function buildCommonConfig(): CommonConfig {
    setupEnvironment();

    return {
        appVersion: process.env.APP_VERSION!,
        appEnvironment: process.env.APP_ENVIRONMENT!,
        // appUrl: process.env.APP_URL!,
        // TODO: Use `appUrl` instead of `dashboardUrl`
        dashboardUrl: process.env.DASHBOARD_URL!,
        weblicantUrl: process.env.WEBLICANT_URL!,

        sentryDsn: process.env.SENTRY_DSN || null,
        sentryRelease: process.env.SENTRY_RELEASE || null,
        sentryTracesSampleRate: !!process.env.SENTRY_TRACES_SAMPLE_RATE
            ? parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE)
            : 0,

        analytics: {
            posthogApiKey: process.env.POSTHOG_API_KEY || undefined
        },
        googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY!,
        leaflet: {
            accessToken: process.env.MAPBOX_ACCESS_TOKEN!
        },

        deeplink: {
            job: process.env.APP_DEEPLINK_JOB_PATTERN!
        },

        showDeleteJob: true,

        showRequestDocuments: true,
        showCalendlyLink: true,

        showAdTypeOnlyForPremiumJob: false
    };
}
