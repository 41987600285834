import * as React from 'react';

import { isProductWithProductName } from '../../modules/checkout/products';
import { Job } from '../../modules/jobs/types';

import AdType from '../AdType';
import ProductType from '../ProductType';

export type JobTypeProps = {
    job: Job;
};

function JobType({ job }: JobTypeProps) {
    if (!!job.product && isProductWithProductName(job.product)) {
        return <ProductType product={job.product} />;
    }

    return <AdType adType={job.ad_type} />;
}

export default JobType;
