import * as React from 'react';

import { isProductWithProductName } from '../../modules/checkout/products';
import { PackageSubset } from '../../modules/packages/types';
import AdType from '../AdType/AdType';
import ProductType from '../ProductType';

export type PackageTypeProps = {
    pkg: PackageSubset<'product' | 'ad_type'>;
};

function PackageType({ pkg }: PackageTypeProps) {
    if (!!pkg.product && isProductWithProductName(pkg.product)) {
        return <ProductType product={pkg.product} />;
    }

    return <AdType adType={pkg.ad_type} />;
}

export default PackageType;
