import { ProductV2ProductSlug } from './types';

export const productsWithOwnProductName: ProductV2ProductSlug[] = [
    'truffls_pro_2023-09',
    'truffls_pro_plus_2023-09',
    'truffls_pro_ultimate_2023-09',
    'truffls_pro_3m-2024-04',
    'truffls_pro_plus_3m-2024-04',
    'truffls_pro_ultimate_3m-2024-04'
];

export function isProductWithProductName(productSlug: ProductV2ProductSlug) {
    return productsWithOwnProductName.includes(productSlug);
}

export function getProductNameMessageId(productSlug: ProductV2ProductSlug): string {
    return `CONSTANT.PRODUCT.${productSlug}`;
}
