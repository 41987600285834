import Big from 'big.js';
import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { links } from '../../constants';
import { getProductByProductSlug } from '../../modules/checkout/repositories/products';
import { CheckoutProcessState$Advertise$Product, CheckoutProcessState$BulkUp } from '../../modules/checkout/types';
import { calculateNetto, calculateUnitPrice, hasAdType, ProductV2WithAdType } from '../../modules/checkout/utils';

import FormError from '../../components/FormError';
import OrderSummaryTable, { OrderSummaryTablePosition } from '../../components/OrderSummaryTable';
import Product from '../../components/Product';
import ProductPrice from '../../components/ProductPrice';
import ProductPromoteText from '../../components/ProductPromoteText';
import UnknownErrorPage from '../../components/UnknownErrorPage/UnknownErrorPage';

import './CheckoutConfirmAdvertisementForm.style.scss';

type Props = {
    checkoutProcessState: CheckoutProcessState$BulkUp | CheckoutProcessState$Advertise$Product;

    vatRate: number;

    submitButtonLabel: React.ReactNode;
    onSubmit: Function;
    actions: React.ReactNode;

    intl: IntlShape;
};

type State = {
    submitted: boolean;
    invalid: boolean;

    generalTermsAndConditionsChecked: boolean;
};

class CheckoutConfirmAdvertisementForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            submitted: false,
            invalid: false,

            generalTermsAndConditionsChecked: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeGeneralTermsAndConditions = this.handleChangeGeneralTermsAndConditions.bind(this);
    }

    handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        const { generalTermsAndConditionsChecked } = this.state;

        this.setState({
            submitted: true
        });

        if (!generalTermsAndConditionsChecked) {
            this.setState({ invalid: true });

            return;
        }

        this.props.onSubmit();
    }

    handleChangeGeneralTermsAndConditions() {
        this.setState({
            generalTermsAndConditionsChecked: !this.state.generalTermsAndConditionsChecked
        });
    }

    render() {
        const { checkoutProcessState, actions } = this.props;

        const productSlug = checkoutProcessState.data.product;
        let productWithAdType: ProductV2WithAdType | null = null;
        try {
            const product = getProductByProductSlug(productSlug);

            if (hasAdType(product)) {
                productWithAdType = product;
            }
        } catch (error) {
            // Nothing
        }

        if (!productWithAdType) {
            return <UnknownErrorPage />;
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        {this.renderContent({ product: productWithAdType })}
                    </div>
                </div>

                {actions}
            </form>
        );
    }

    renderContent({ product }: { product: ProductV2WithAdType }) {
        const { submitted, invalid } = this.state;

        let errorAlertElement: React.ReactNode = null;
        if (submitted && invalid) {
            errorAlertElement = (
                <FormError messageId="CHECKOUT_CONFIRM_ADVERTISEMENT_FORM.ERROR.GENERAL_TERMS_AND_CONDTIONS.REQUIRED" />
            );
        }

        return (
            <div>
                {errorAlertElement}

                <div className="CheckoutConfirmAdvertisementForm__summaries">
                    {this.renderProductOverview({ product })}

                    {this.renderOrderSummary({ product })}
                </div>

                <hr />

                {this.renderGeneralTermsAndConditionsCheckbox()}
            </div>
        );
    }

    renderProductOverview({ product }: { product: ProductV2WithAdType }) {
        const { intl } = this.props;
        const { locale } = intl;

        // Check that
        const content = product.content(locale);
        const price = parseFloat(product.price);

        return (
            <div className="CheckoutConfirmAdvertisementForm__product-overview">
                <Product
                    title={content.title}
                    price={<ProductPrice value={price} />}
                    sections={content.sections}
                    promote={product.promote && <ProductPromoteText />}
                />
            </div>
        );
    }

    renderOrderSummary({ product }: { product: ProductV2WithAdType }) {
        const { vatRate } = this.props;

        const quantity = 1;

        const unitPrice = calculateUnitPrice(product.id, quantity);
        const totalPrice = calculateNetto(product.id, quantity);

        const positions: OrderSummaryTablePosition[] = [
            {
                identifier: product.ad_type,
                quantity,
                duration: product.duration_in_days,

                unitPrice: unitPrice.toNumber(),
                totalPrice: unitPrice.times(quantity).toNumber()
            }
        ];

        const netAmount: Big = positions.reduce((amount: Big, position) => {
            return amount.plus(position.totalPrice);
        }, new Big(0));
        const vatAmount = netAmount.times(vatRate);
        const grossAmount = netAmount.plus(vatAmount);

        const formattedPositions = positions.map((position) => {
            return {
                ...position,

                unitPrice: unitPrice.toNumber(),
                totalPrice: totalPrice.toNumber()
            };
        });
        const formattedNetAmount = netAmount.toNumber();
        const formattedVatAmount = vatAmount.toNumber();
        const formattedGrossAmount = grossAmount.toNumber();

        return (
            <OrderSummaryTable
                positions={formattedPositions}
                netAmount={formattedNetAmount}
                vatRate={vatRate}
                vatAmount={formattedVatAmount}
                grossAmount={formattedGrossAmount}
            />
        );
    }

    renderGeneralTermsAndConditionsCheckbox() {
        const {
            submitted,
            invalid,

            generalTermsAndConditionsChecked
        } = this.state;

        const gtcLabel = this.renderGeneralTermsAndConditionsLabel();

        const className = classNames('tf-form-element', {
            'tf-has-error': submitted && invalid
        });

        return (
            <div className={className}>
                <div className="tf-form-element__control">
                    <div className="tf-checkbox">
                        <input
                            type="checkbox"
                            name="generalTermsAndConditions"
                            id="checkoutConfirmAdvertisementForm__generalTermsAndConditions"
                            checked={generalTermsAndConditionsChecked}
                            onChange={this.handleChangeGeneralTermsAndConditions}
                        />

                        <label
                            htmlFor="checkoutConfirmAdvertisementForm__generalTermsAndConditions"
                            className="tf-checkbox__label"
                        >
                            <span className="tf-checkbox__faux"></span>
                            <span className="tf-checkbox__label-text">{gtcLabel}</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    renderGeneralTermsAndConditionsLabel() {
        const { intl, submitButtonLabel } = this.props;

        const generalTermsAndConditionsOfCompany = (
            <FormattedMessage id={'CHECKOUT_CONFIRM_ADVERTISEMENT_FORM.GENERAL_TERMS_AND_CONDITIONS_OF_COMPANY'} />
        );

        const generalTermsAndConditionsLink = (
            <a
                href={links.gtc[intl.locale]}
                target="_blank"
                rel="noopener"
                className="CheckoutConfirmAdvertisementForm__gtc-link"
            >
                {generalTermsAndConditionsOfCompany}
            </a>
        );

        const buttonLabel = <strong>{submitButtonLabel}</strong>;

        return (
            <span className="CheckoutConfirmAdvertisementForm__gtc-label">
                <FormattedMessage
                    id={'CHECKOUT_CONFIRM_ADVERTISEMENT_FORM.GENERAL_TERMS_AND_CONDITIONS.LABEL'}
                    values={{ generalTermsAndConditionsLink }}
                />

                <br />
                <br />
                <FormattedMessage
                    id={'CHECKOUT_CONFIRM_ADVERTISEMENT_FORM.GENERAL_TERMS_AND_CONDITIONS.TEXT'}
                    values={{
                        generalTermsAndConditionsOfCompany,
                        generalTermsAndConditionsLink,
                        buttonLabel
                    }}
                />
            </span>
        );
    }
}

export default injectIntl(CheckoutConfirmAdvertisementForm);
