import { ProductV2, ProductV2AdType, ProductV2Id, ProductV2ProductSlug } from '../types';
import { parseProducts } from './helpers/products-parser';

const products = parseProducts();

export type GetProductsFilters = {
    bookable?: boolean;
};

export function getProducts({ bookable }: GetProductsFilters = {}): ProductV2[] {
    let filteredProducts = products;

    if (typeof bookable !== 'undefined') {
        filteredProducts = filterProductsByBookable(filteredProducts, bookable);
    }

    return filteredProducts;
}

export function filterProductsByBookable(products: ProductV2[], bookable: boolean): ProductV2[] {
    return products.filter((p) => p.bookable === bookable);
}

export function findProductById(products: ProductV2[], id: ProductV2Id): ProductV2 | null {
    return products.find((p) => p.id === id) ?? null;
}

export function findProductByAdType(products: ProductV2[], adType: ProductV2AdType): ProductV2 | null {
    return products.find((p) => p.ad_type === adType) ?? null;
}

export function findProductByProductSlug(products: ProductV2[], productSlug: ProductV2ProductSlug): ProductV2 | null {
    return products.find((p) => p.product_slug === productSlug) ?? null;
}

/**
 * @deprecated Use `getProducts` and `findProductById` instead.
 */
export function getProductById(id: ProductV2Id): ProductV2 {
    const product = findProductById(getProducts(), id);

    if (!product) {
        throw new RangeError(`Product with id ${id} doesn't exist`);
    }

    return product;
}

/**
 * @deprecated Use `getProducts` and `findProductByAdType` instead.
 */
export function getProductByAdType(adType: ProductV2AdType): ProductV2 {
    const product = findProductByAdType(getProducts(), adType);

    if (!product) {
        throw new RangeError(`Product with ad type '${adType}' doesn't exist`);
    }

    return product;
}

/**
 * @deprecated Use `getProducts` and `findProductByProductSlug` instead.
 */
export function getProductByProductSlug(productSlug: ProductV2ProductSlug): ProductV2 {
    const product = findProductByProductSlug(getProducts(), productSlug);

    if (!product) {
        throw new RangeError(`Product with product slug '${productSlug}' doesn't exist`);
    }

    return product;
}
