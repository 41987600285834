import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import classNames from 'classnames';

import FormattedCurrencyNumber from '../FormattedCurrencyNumber';

import classes from './OrderSummaryTable.module.scss';

export type OrderSummaryTablePosition = {
    identifier: string;
    quantity: number;
    duration: number | null;

    unitPrice: number;
    totalPrice: number;
};

export type OrderSummaryTableProps = {
    positions: OrderSummaryTablePosition[];

    netAmount: number;
    vatRate: number;
    vatAmount: number;
    grossAmount: number;

    className?: string;
};

class OrderSummaryTable extends React.Component<OrderSummaryTableProps> {
    render() {
        const { positions, className } = this.props;

        const itemElements = positions.map(this.renderItem);

        return (
            <section className={classNames(classes.root, className)}>
                <div className={classes.data}>
                    {this.renderHeader()}

                    <ul className={classes.positions}>{itemElements}</ul>
                </div>

                {this.renderResults()}
            </section>
        );
    }

    renderHeader() {
        return (
            <header className={classes.header}>
                <div className={classes.headerItem}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.DESCRIPTION" />
                </div>
                <div className={classNames(classes.headerItem, classes.headerItemDetail)}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.UNIT_PRICE" />
                </div>
                <div className={classNames(classes.headerItem, classes.headerItemDetail)}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.QUANTITY" />
                </div>
                <div className={classNames(classes.headerItem, classes.headerItemDetail)}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.TOTAL" />
                </div>
            </header>
        );
    }

    renderItem(item: OrderSummaryTablePosition, index: number) {
        return (
            <li key={index} className={classes.position}>
                <div className={classes.positionDescription}>
                    <div className={classes.positionDescriptionMain}>
                        <FormattedMessage
                            id="ORDER_SUMMARY_TABLE.POSITION_DESCRIPTION"
                            values={{
                                type: <FormattedMessage id={`CONSTANT.PRODUCT.${item.identifier}`} />
                            }}
                        />
                    </div>
                    {item.duration && (
                        <div className={classes.positionDescriptionSub}>
                            <FormattedMessage
                                id="CONSTANT.PRODUCT_PERIOD_OF_PERFORMANCE"
                                values={{ duration: item.duration }}
                            />
                        </div>
                    )}
                </div>

                <div className={classes.positionDetail}>
                    <div className={classes.positionDetailLabel}>
                        <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.UNIT_PRICE" />
                    </div>

                    <div className={classes.positionDetailValue}>
                        <FormattedCurrencyNumber
                            value={item.unitPrice}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </div>
                </div>
                <div className={classes.positionDetail}>
                    <div className={classes.positionDetailLabel}>
                        <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.QUANTITY" />
                    </div>

                    <div className={classes.positionDetailValue}>{item.quantity}</div>
                </div>
                <div className={classes.positionDetail}>
                    <div className={classes.positionDetailLabel}>
                        <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.TOTAL" />
                    </div>

                    <div className={classes.positionDetailValue}>
                        <FormattedCurrencyNumber
                            value={item.totalPrice}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </div>
                </div>
            </li>
        );
    }

    renderResults() {
        const { netAmount, vatRate, vatAmount, grossAmount } = this.props;

        return (
            <div className={classes.summary}>
                <div className={classes.summaryItem}>
                    <div className={classes.summaryItemLabel}>
                        <FormattedMessage id="ORDER_SUMMARY_TABLE.SUBTOTAL" />
                    </div>

                    <div className={classes.summaryItemValue}>
                        <FormattedCurrencyNumber
                            value={netAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </div>
                </div>

                <div className={classes.summaryItem}>
                    <div className={classes.summaryItemLabel}>
                        <FormattedMessage
                            id="ORDER_SUMMARY_TABLE.VAT_RATE"
                            values={{
                                rate: (
                                    <FormattedNumber
                                        // eslint-disable-next-line react/style-prop-object
                                        style="percent"
                                        value={vatRate}
                                    />
                                )
                            }}
                        />
                    </div>

                    <div className={classes.summaryItemValue}>
                        <FormattedCurrencyNumber
                            value={vatAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </div>
                </div>

                <div className={classNames(classes.summaryItem, classes.summaryTotal)}>
                    <div className={classes.summaryItemLabel}>
                        <FormattedMessage id="ORDER_SUMMARY_TABLE.GRAND_TOTAL" />
                    </div>

                    <div className={classes.summaryItemValue}>
                        <FormattedCurrencyNumber
                            value={grossAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderSummaryTable;
